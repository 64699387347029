<template>
  <div class="content">
    <div class="row">
      <div class="col-md-6">
        <card class="stacked-form">
          <h3 class="card-title">{{ $t('transactions.filters') }}</h3>
          <form @submit.prevent="filterTransactions">
            <div>
              <div class="row">
                <div class="col-sm-12">
                  <p slot="header" class="card-title">{{ $t('transactions.cardNumber') }}</p>
                  <base-input
              type="text"
              :placeholder="$t('transactions.cardNumber')" v-model="cardNumber"></base-input>
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-sm-12">
                  <p slot="header" class="card-title">{{ $t('transactions.files') }}</p>
                  <el-select
                    multiple
                    class="select-primary"
                    size="large"
                    v-model="transactionsFiles"
                    collapse-tags
                    :placeholder="$t('transactions.files')"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="file in avalaibleFiles"
                      class="select-primary"
                      :value="file"
                      :label="file"
                      :key="file"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <br/> -->
              <div class="row">
                <div class="col-sm-6">
                  <p slot="header" class="card-title">{{ $t('transactions.fromDate') }}</p>
                  <base-input>
                    <el-date-picker type="date" :placeholder="$t('transactions.fromDate')" v-model="startDate"
                    :picker-options="{ disabledDate: (time) => afterEndDate(time), shortcuts: shortcuts }"
                    clearable value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </base-input>
                </div>
                <div class="col-sm-6">
                  <p slot="header" class="card-title">{{ $t('transactions.toDate') }}</p>
                  <base-input>
                    <el-date-picker type="date" :placeholder="$t('transactions.toDate')" v-model="endDate" clearable value-format="yyyy-MM-dd"
                    :picker-options="{ disabledDate: (time) => beforeStartDate(time), shortcuts: shortcuts }">
                    </el-date-picker>
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p slot="header" class="card-title">{{ $t('transactions.score') }}</p>
                  <br/>
                  <slider style="width: 88%; margin-left: 5%" id="sliderScores" v-model="rangeScores" :connect="true" :range="{'min': 0,'20%': 20,'55%': 55, '90%': 90,'max': 100}" type="primary"
                    :options="{tooltips: true, snap: true}"></slider>
                  <br/>
                </div>
              </div>
              <div class="row">
                <!-- <div class="col-sm-6">
                  <base-checkbox v-model="highScores">{{ $t('transactions.highScores') }}</base-checkbox>
                </div> -->
                <div class="col-sm-6">
                  <base-checkbox v-model="voyageImpossible">{{ $t('transactions.voyageImpossible') }}</base-checkbox>
                </div>
                <div class="col-sm-6">
                  <base-checkbox v-model="notVerified">{{ $t('transactions.notVerified') }}</base-checkbox>
                </div>
              </div>
              <br/>
              <base-button class="mt-3" native-type="submit" type="primary">
                {{ $t('transactions.btnApply') }}
              </base-button>
            </div>
          </form>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">{{ $t('transactions.list') }}</h4>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
              <!-- <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                ></el-input>
              </base-input> -->
            </div>
            <el-table id="mainarr" :data="queriedData">
              <el-table-column :min-width="250" :label="$t('transactions.cardNumber')">
                <!-- sortable -->
                <div slot-scope="props">
                  <a class="link" :href='"#/card-transactions?card="+props.row.card_id'>{{props.row.card_id}}</a>
                </div>
              </el-table-column>
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
                :sortable="column.sortable"
              ></el-table-column>
              <!-- sortable -->
              <el-table-column :min-width="135" align="right" :label="$t('transactions.actions')">
                <div slot-scope="props">
                  <base-button
                    @click.native="handleCardTransactions(props.$index, props.row)"
                    class="btn-link"
                    type="primary"
                    icon
                  >
                    <i class="tim-icons icon-tap-02"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";
import {Table, TableColumn, Select, Option, DatePicker} from 'element-ui';
import {BaseAlert, BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {refreshContext, formatDate} from "@/utils";
import {mapMutations} from 'vuex';
import { Slider } from "src/components";

export default {
  components: {
    BaseAlert,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    Slider,
    Modal
  },
  computed: {
    queriedData() {
      let result = this.transactions;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0 ? this.searchedData.length : this.transactions.length;
    }
  },
  data() {
    return {
      token: localStorage.getItem("userToken") || "",
      files: [],
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      newAlert: "",
      searchQuery: '',
      propsToSearch: ['card_number', 'transactions_count', 'max_score', 'all_verified'],
      tableColumns: [
        // {
        //   prop: 'card_number',
        //   label: this.$t('transactions.cardNumber'),
        //   minWidth: 250
        // },
        {
          prop: 'transactions_count',
          label: this.$t('transactions.nbTransactions'),
          minWidth: 120
        },
        {
          prop: 'max_score',
          label: this.$t('transactions.highestScore'),
          minWidth: 120
        },
        {
          prop: 'fraud_amount',
          label: this.$t('transactions.fraudAmount'),
          minWidth: 120
          // sortable: true
        },
        {
          prop: 'all_verified',
          label: this.$t('transactions.isVerified'),
          minWidth: 120
        }
      ],
      transactions: this.getTransactions() || [],
      searchedData: [],
      fuseSearch: null,
      upload: null,
      startDate: null,
      endDate: null,
      highScores: false,
      rangeScores: [0, 100],
      transactionsFiles: [],
      avalaibleFiles: [],
      notVerified: false,
      cardNumber: null,
      voyageImpossible: false,
      isOpenFilters: false,
      isFilterModified: false,
      shortcuts: [
        {
          text: 'Today',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        },
        {
          text: 'Yesterday',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          },
        },
        {
          text: 'A week ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          },
        },
        {
          text: 'A month ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', date)
          },
        },
      ]
    };
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'switchOrganisation':
          this.transactions = this.getTransactions() || [];
          this.pagination.currentPage = 1;
          this.searchedData = [];
          break;
      }
    });
    this.fuseSearch = new Fuse(this.transactions, {
      keys: ['name', 'email'],
      threshold: 0.3
    });
    this.getTransactionFiles();
  },
  methods: {
    ...mapMutations([
      'switchOrganisation', // map `this.increment()` to `this.$store.commit('increment')`
    ]),
    getTransactionFiles() {
      this.token = localStorage.getItem("userToken") || "";
      const fetchOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };
      const apiUrl = this.$apiEndpoint + `/api/orgs/` + this.$store.getters.getCurrentOrganisation.id + `/transactions-files`;
      fetch(apiUrl, fetchOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 401) {
            this.logout();
            throw new Error("Accès non autorisé. Veuillez-vous connecter.");
          } else {
            throw new Error("Unauthorized resource");
          }
        })
        .then((data) => {
          this.avalaibleFiles = [];
          data.forEach(d => {
            this.avalaibleFiles.push(d.file);
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'danger',
            message: `Error - ${error.message}...`,
            icon: 'tim-icons icon-bell-55'
          });
        });
    },
    getTransactions() {
      this.token = localStorage.getItem("userToken") || "";
      let filters = "";
      filters += (this.startDate) ? "&start_date=" + this.startDate : "";
      filters += (this.endDate) ? "&end_date=" + this.endDate : "";
      filters += (this.notVerified) ? "&not_verified=" + this.notVerified : "";
      filters += (this.highScores) ? "&high_score=" + this.highScores : "";
      filters += (this.cardNumber) ? "&card=" + this.cardNumber : "";
      filters += (this.voyageImpossible) ? "&voyage_impossible=" + this.voyageImpossible : "";
      filters += (this.rangeScores) ? "&start_score=" + this.rangeScores[0] + "&end_score=" + this.rangeScores[1] : "";
      filters += (this.transactionsFiles && this.transactionsFiles.length > 0) ? "&files=" + this.transactionsFiles : "";
      if (filters.length > 0) {
        filters =  "?" + filters.substring(1);
      }
      const fetchOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };
      const apiUrl = this.$apiEndpoint + `/api/orgs/` + this.$store.getters.getCurrentOrganisation.id + `/transactions/` + filters;
      fetch(apiUrl, fetchOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 401) {
            this.logout();
            throw new Error("Accès non autorisé. Veuillez-vous connecter.");
          } else {
            throw new Error("Unauthorized resource");
          }
        })
        .then((data) => {
          let returnTable = [];
          data.forEach(d => {
            returnTable.push({
              "id": d.id,
              "card_number": d.card_number, //<a class="link" href={"#/card-transactions?card="+d.card_id}>{d.card_number}</a>,
              "transactions_count": d.transactions_count,
              "max_score": (d.max_score == -1) ? '- %' : (Math.round(d.max_score * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 }) + ' %',
              "all_verified": <base-checkbox checked={d.all_verified} disabled></base-checkbox>,
              "card_id": d.card_id,
              "fraud_amount": d.fraud_amount.toLocaleString(undefined, { minimumFractionDigits: 2 })
            });
          });
          this.transactions = returnTable;
        })
        .catch((error) => {
          this.$notify({
            type: 'danger',
            message: `Error - ${error.message}...`,
            icon: 'tim-icons icon-bell-55'
          });
        });
    },
    filterTransactions() {
      this.getTransactions();
    },
    handleCardTransactions(index, row) {
      this.$router.push("/card-transactions?card="+row.card_id);
    },
    afterEndDate(time) {
      if (this.endDate) {
        return time.getTime() > new Date(this.endDate)
      }
    },
    beforeStartDate(time) {
      let beforeDate = new Date(this.startDate);
      beforeDate.setDate(beforeDate.getDate() - 1)
      return time.getTime() < beforeDate
    },
    logout() {
      localStorage.removeItem('userToken'); // Adjust based on your storage method
      localStorage.removeItem('startDate');
      localStorage.removeItem('endDate');
      localStorage.removeItem('exclusions');
      this.$store.dispatch('logout'); // If using Vuex

      this.$router.push("/login");
    }
  },
  watch: {
    searchQuery(value) {
      let result = this.transactions;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  }
};
</script>

<style>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

#mainarr th {
  background-color: unset;
}
</style>

<template>
  <div class="content">
    <div class="row">
      <router-link class="nav-item" tag="li" to="/transactions">
    <a class="nav-link text-primary">
      <i class="tim-icons icon-minimal-left"></i>{{ $t('transactions.goBack') }}
    </a>
  </router-link>
</div>
    <div class="row">
      <div class="col-md-6">
        <card class="stacked-form">
          <h3 class="card-title">{{ $t('transactions.cardNumber') }} <b>{{ this.cardNumber }}</b></h3>
          <form @submit.prevent="filterTransactions">
            <div>
              <div class="row">
                <div class="col-sm-3">
                  <p slot="header" class="card-title">{{ $t('transactions.clientNum') }}</p>
                  <base-input
                    disabled
                    v-model="clientNum"
                    addon-left-icon="tim-icons icon-badge"
                  >
                  </base-input>
                </div>
                <div class="col-sm-9">
                  <p slot="header" class="card-title">{{ $t('transactions.clientName') }}</p>
                  <base-input
                    disabled
                    v-model="clientName"
                    addon-left-icon="tim-icons icon-single-02"
                  >
                  </base-input>
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-sm-12">
                  <p slot="header" class="card-title">{{ $t('transactions.files') }}</p>
                  <el-select
                    multiple
                    class="select-primary"
                    size="large"
                    v-model="transactionsFiles"
                    collapse-tags
                    :placeholder="$t('transactions.files')"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="file in avalaibleFiles"
                      class="select-primary"
                      :value="file"
                      :label="file"
                      :key="file"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <br/> -->
              <div class="row">
                <div class="col-sm-6">
                  <p slot="header" class="card-title">{{ $t('transactions.fromDate') }}</p>
                  <base-input>
                    <el-date-picker type="date" :placeholder="$t('transactions.fromDate')" v-model="startDate"
                    :picker-options="{ disabledDate: (time) => afterEndDate(time), shortcuts: shortcuts }"
                    clearable value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </base-input>
                </div>
                <div class="col-sm-6">
                  <p slot="header" class="card-title">{{ $t('transactions.toDate') }}</p>
                  <base-input>
                    <el-date-picker type="date" :placeholder="$t('transactions.toDate')" v-model="endDate" clearable value-format="yyyy-MM-dd"
                    :picker-options="{ disabledDate: (time) => beforeStartDate(time), shortcuts: shortcuts }">
                    </el-date-picker>
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p slot="header" class="card-title">{{ $t('transactions.score') }}</p>
                  <br/>
                  <slider style="width: 88%; margin-left: 5%" id="sliderScores" v-model="rangeScores" :connect="true" :range="{'min': 0,'20%': 20,'55%': 55, '90%': 90,'max': 100}" type="primary"
                    :options="{tooltips: true, snap: true}"></slider>
                  <br/>
                </div>
              </div>
              <div class="row">
                <!-- <div class="col-sm-6">
                  <base-checkbox v-model="highScores">{{ $t('transactions.highScores') }}</base-checkbox>
                </div> -->
                <div class="col-sm-6">
                  <base-checkbox v-model="voyageImpossible">{{ $t('transactions.voyageImpossible') }}</base-checkbox>
                </div>
                <div class="col-sm-6">
                  <base-checkbox v-model="notVerified">{{ $t('transactions.notVerified') }}</base-checkbox>
                </div>
              </div>
              <br/>
              <base-button class="mt-3" native-type="submit" type="primary">
                {{ $t('transactions.btnApply') }}
              </base-button>
            </div>
          </form>
        </card>
      </div>
      <div class="col-md-6">
        <card>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <base-button class="card-title" size="sm" type="danger" disabled>{{ $t('transactions.legendRed') }}</base-button>
            <base-button class="card-title" size="sm" type="primary" disabled>{{ $t('transactions.legendGreen') }}</base-button>
          </div>
          <div id="map" style="width: 100%; height: 400px;" class="d-flex justify-content-around"  align="right"></div>
          <div id="popup" class="ol-popup">
            <div id="popup-content"></div>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <h4 slot="header" class="card-title">{{ $t('transactions.list') }}</h4>
            <h5 class="card-title">{{ $t('transactions.fraudAmount') }}</h5>
          </div>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
              <!-- <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                ></el-input>
              </base-input> -->
              <base-input
                  disabled
                  class="mb-3 search-input"
                  v-model="fraudAmount"
                  addon-left-icon="tim-icons icon-money-coins"
                >
              </base-input>
            </div>
            <el-table id="mainarr" :data="queriedData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
                :sortable="column.sortable"
              ></el-table-column>
              <!-- sortable -->
              <el-table-column :min-width="60" :label="$t('transactions.isVerified')">
                <!-- sortable -->
                <div slot-scope="props">
                  <base-checkbox :disabled="!props.row.is_verified" :checked="props.row.is_verified" v-model="props.row.is_verified"></base-checkbox>
                </div>
              </el-table-column>
              <el-table-column :min-width="60" :label="$t('transactions.fraud')">
                <!-- sortable -->
                <div slot-scope="props">
                  <base-checkbox disabled type="danger" :checked="props.row.is_fraud"></base-checkbox>
                  <!-- v-model="props.row.is_fraud" -->
                </div>
              </el-table-column>
              <el-table-column :min-width="150" align="right" :label="$t('transactions.actions')">
                <div slot-scope="props">
                  <base-button v-if="!props.row.is_verified"
                    @click.native="handleNotFraud(props.$index, props.row)"
                    type="primary"
                    icon
                  >
                    <i class="tim-icons icon-check-2"></i>
                  </base-button>
                  &nbsp;
                  <base-button v-if="!props.row.is_verified"
                    @click.native="handleFraud(props.$index, props.row)"
                    type="warning"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                  &nbsp;&nbsp;&nbsp;
                  <el-tooltip
                    :content="props.row.station.name"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click.native="handleMap(props.$index, props.row)"
                      icon
                    >
                      <i class="tim-icons icon-square-pin"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {Table, TableColumn, Select, Option, DatePicker} from 'element-ui';
import {BaseAlert, BasePagination} from 'src/components';
import Fuse from 'fuse.js';
// import swal from 'sweetalert2';
import {refreshContext, formatDate, formatDateTime} from "@/utils";
import {mapMutations} from 'vuex';
// import FileUpload from '../../components/FileUpload.vue';

import TileLayer from 'ol/layer/Tile.js';
import VectorLayer from 'ol/layer/Vector.js';
import Point from 'ol/geom/Point.js';
import {Icon, Style} from 'ol/style.js';
import {Feature, Map, Overlay, View} from 'ol/index.js';
import {OSM, Vector as VectorSource} from 'ol/source.js';
import {useGeographic, fromLonLat} from 'ol/proj.js';
import { Slider } from "src/components";

import 'ol/ol.css';

export default {
  components: {
    BaseAlert,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    Slider
  },
  computed: {
    queriedData() {
      let result = this.transactions;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0 ? this.searchedData.length : this.transactions.length;
    }
  },
  data() {
    return {
      token: localStorage.getItem("userToken") || "",
      files: [],
      card: "",
      cardNumber: "",
      map: null,
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      newAlert: "",
      searchQuery: '',
      propsToSearch: ['date', /*'time'*/, 'fuel_type', 'quantity', 'distance', 'score', /*'fraud'*/, 'info'],
      tableColumns: [
        {
          prop: 'date',
          label: this.$t('transactions.date'),
          minWidth: 150
        },
        // {
        //   prop: 'time',
        //   label: this.$t('transactions.time'),
        //   minWidth: 100
        // },
        {
          prop: 'fuel_type',
          label: this.$t('transactions.fuelType'),
          minWidth: 120
        },
        {
          prop: 'quantity',
          label: this.$t('transactions.quantity'),
          minWidth: 120
        },
        // {
        //   prop: 'unit_price',
        //   label: this.$t('transactions.unitPrice'),
        //   minWidth: 80
        // },
        {
          prop: 'price',
          label: this.$t('transactions.price'),
          minWidth: 100
        },
        {
          prop: 'distance',
          label: this.$t('transactions.distance'),
          minWidth: 90
        },
        {
          prop: 'score',
          label: this.$t('transactions.score'),
          minWidth: 80 
        },
        {
          prop: 'info',
          label: this.$t('transactions.info'),
          minWidth: 150
        },
        {
          prop: 'station.department',
          label: this.$t('transactions.department'),
          minWidth: 50
        },
        // {
        //   prop: 'fraud',
        //   label: this.$t('transactions.fraud'),
        //   minWidth: 50
        // }
      ],
      transactions: this.getTransactions() || [],
      searchedData: [],
      fuseSearch: null,
      upload: null,
      startDate: null,
      endDate: null,
      highScores: false,
      notVerified: false,
      voyageImpossible: false,
      clientNum: null,
      clientName: null,
      fraudAmount: 0.00,
      rangeScores: [0, 100],
      transactionsFiles: [],
      avalaibleFiles: [],
      isOpenFilters: false,
      isFilterModified: false,
      shortcuts: [
        {
          text: 'Today',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        },
        {
          text: 'Yesterday',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          },
        },
        {
          text: 'A week ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          },
        },
        {
          text: 'A month ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', date)
          },
        },
      ]
    };
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'switchOrganisation':
          this.transactions = this.getTransactions() || [];
          this.pagination.currentPage = 1;
          this.searchedData = [];
          break;
      }
    });
    this.fuseSearch = new Fuse(this.transactions, {
      keys: ['name', 'email'],
      threshold: 0.3
    });
    this.checkUrlParameter();

    /// MAP
    this.resetMap();
    /// MAP
    this.getTransactionFiles();
    this.setFraudAmount();

  },
  methods: {
    ...mapMutations([
      'switchOrganisation', // map `this.increment()` to `this.$store.commit('increment')`
    ]),
    getTransactionFiles() {
      this.token = localStorage.getItem("userToken") || "";
      const fetchOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };
      const apiUrl = this.$apiEndpoint + `/api/orgs/` + this.$store.getters.getCurrentOrganisation.id + `/transactions-files`;
      fetch(apiUrl, fetchOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 401) {
            this.logout();
            throw new Error("Accès non autorisé. Veuillez-vous connecter.");
          } else {
            throw new Error("Unauthorized resource");
          }
        })
        .then((data) => {
          this.avalaibleFiles = [];
          data.forEach(d => {
            this.avalaibleFiles.push(d.file);
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'danger',
            message: `Error - ${error.message}...`,
            icon: 'tim-icons icon-bell-55'
          });
        });
    },
    getTransactions() {
      this.resetMap();
      this.token = localStorage.getItem("userToken") || "";
      let filters = "";
      filters += (this.startDate) ? "&start_date=" + this.startDate : "";
      filters += (this.endDate) ? "&end_date=" + this.endDate : "";
      filters += (this.notVerified) ? "&not_verified=" + this.notVerified : "";
      filters += (this.highScores) ? "&high_score=" + this.highScores : "";
      filters += (this.voyageImpossible) ? "&voyage_impossible=" + this.voyageImpossible : "";
      filters += (this.rangeScores) ? "&start_score=" + this.rangeScores[0] + "&end_score=" + this.rangeScores[1] : "";
      filters += (this.transactionsFiles && this.transactionsFiles.length > 0) ? "&files=" + this.transactionsFiles : "";
      if (filters.length > 0) {
        filters =  "?" + filters.substring(1);
      }
      const fetchOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };
      if (!this.card) {
        this.checkUrlParameter();
      }
      const apiUrl = this.$apiEndpoint + `/api/orgs/` + this.$store.getters.getCurrentOrganisation.id + `/transactions/` + this.card + filters;
      fetch(apiUrl, fetchOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 401) {
            this.logout();
            throw new Error("Accès non autorisé. Veuillez-vous connecter.");
          } else {
            throw new Error("Unauthorized resource");
          }
        })
        .then((data) => {
          let returnTable = [];
          let features_fraud = [];
          let features_clean = [];
          data.forEach(d => {
            returnTable.push({
              "id": d.id,
              'date': formatDateTime(d.transaction_at),
              'fuel_type': d.fuel_type,
              'quantity': d.quantity.toLocaleString(undefined, { minimumFractionDigits: 2 }),
              'unit_price': d.unit_price.toLocaleString(undefined, { minimumFractionDigits: 2 }),
              'price': d.price.toLocaleString(undefined, { minimumFractionDigits: 2 }),
              'distance': (d.distance == -1) ? '-' : (Math.round(d.distance * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 }),
              'score': (d.score == -1) ? '- %' : (Math.round(d.score * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 }) + ' %',
              'num_unit_price': d.unit_price,
              'num_price': d.price,
              'num_quantity': d.quantity,
              'num_distance': d.distance,
              'num_score': d.score,
              "is_verified": (d.score == -1) ? true : d.verified,
              // 'verified': <base-checkbox disabled={!d.verified} checked={d.verified}></base-checkbox>,
              "is_fraud": d.fraud,
              // 'fraud': <base-checkbox disabled checked={d.fraud}></base-checkbox>,
              "station_id": d.station_id,
              "station": d.station,
              "info": (d.info == "-1" && d.score == -1) ? "Première transaction" : d.info
            });
            if (data.length > 0) {
              this.clientNum = data[0].client_num
              this.clientName = data[0].client_name
            }
            if (d.score > 80) {
              features_fraud.push(
                new Feature({
                  geometry: new Point(fromLonLat([d.station.longitude, d.station.latitude])),
                  name: d.station.name,
                  color: "red"
                }));
            } else {
              features_clean.push(
                new Feature({
                  geometry: new Point(fromLonLat([d.station.longitude, d.station.latitude])),
                  name: d.station.name,
                  color: "green"
                }));
            }
          });
          this.transactions = returnTable;
          this.setFraudAmount();
          this.map.addLayer(
            new VectorLayer({
              source: new VectorSource({
                features: features_clean
              }),
              style: new Style({
                image: new Icon({
                  anchor: [0.5, 1],
                  crossOrigin: 'anonymous',
                  src: 'img/green.png',
                })
              })
            })
          );
          this.map.addLayer(
            new VectorLayer({
              source: new VectorSource({
                features: features_fraud
              }),
              style: new Style({
                image: new Icon({
                  anchor: [0.5, 1],
                  crossOrigin: 'anonymous',
                  src: 'img/red.png',
                })
              })
            })
          );
          // this.handleMap(0, returnTable[0]);
        })
        .catch((error) => {
          this.$notify({
            type: 'danger',
            message: `Error - ${error.message}...`,
            icon: 'tim-icons icon-bell-55'
          });
        });
    },
    filterTransactions() {
      this.getTransactions();
    },
    handleMap(index, row) {
      this.map.setView(new View({
        center: fromLonLat([row.station.longitude, row.station.latitude]),
        zoom: 6,
      }));
    },
    handleFraud(index, row) {
      this.editTransaction(index, row, true);
    },
    handleNotFraud(index, row) {
      this.editTransaction(index, row, false);
    },
    editTransaction(index, row, isFraud) {
      const fetchOptions = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${this.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: row.id,
          fuel_type: row.fuel_type,
          quantity: row.num_quantity,
          unit_price: row.num_unit_price,
          price: row.num_price,
          score: row.num_score,
          verified: true,
          fraud: isFraud,
        }),
      };
      console.log(fetchOptions.body);
      const apiUrl = this.$apiEndpoint + `/api/transactions/`; // + row.id;
      fetch(apiUrl, fetchOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 401) {
            this.logout();
            throw new Error("Accès non autorisé. Veuillez-vous connecter.");
          } else {
            throw new Error("Unauthorized resource");
          }
        })
        .then((data) => {
          this.transactions[index].id = data.id;
          this.transactions[index].is_verified = data.verified;
          // this.transactions[index].verified = <base-checkbox disabled={!data.verified} checked={data.verified}></base-checkbox>,
          this.transactions[index].is_fraud = data.fraud;
          // this.transactions[index].fraud = <base-checkbox disabled checked={data.fraud}></base-checkbox>
          this.setFraudAmount();
        });
    },
    resetMap() {
      if (this.map) {
        this.map.getLayers().getArray().slice().forEach(layer => this.map.removeLayer(layer))
        this.map.addLayer(new TileLayer({source: new OSM()}));
      } else {
        let map = new Map({
          layers: [
            new TileLayer({
              source: new OSM()
            }),
          ],
          view: new View({
            center: fromLonLat([1.87528, 46.60611]), // center of France
            zoom: 5,
          }),
          target: 'map',
        });
        const element = document.getElementById('popup');
        const content = document.getElementById('popup-content');
        // const closer = document.getElementById('popup-closer');

        // console.log(closer);

        const popup = new Overlay({
          element: element,
          stopEvent: false,
          autoPan: {
            animation: {
              duration: 250
            }
          }
        });
        map.addOverlay(popup);

        map.on('click', function (event) {
          popup.setPosition(undefined);
          const feature = map.getFeaturesAtPixel(event.pixel)[0];
          if (!feature) {
            return;
          }
          const coordinate = feature.getGeometry().getCoordinates();
          popup.setPosition(coordinate);

          content.innerHTML = feature.get('name');
        });

        map.on('pointermove', function (event) {
          const type = map.hasFeatureAtPixel(event.pixel) ? 'pointer' : 'inherit';
          map.getViewport().style.cursor = type;
        });

        this.map = map;
      }
    },
    afterEndDate(time) {
      if (this.endDate) {
        return time.getTime() > new Date(this.endDate)
      }
    },
    beforeStartDate(time) {
      let beforeDate = new Date(this.startDate);
      beforeDate.setDate(beforeDate.getDate() - 1)
      return time.getTime() < beforeDate
    },
    setFraudAmount() {
      let amount = 0;
      this.transactions.forEach(t => {
        if (t.is_fraud) {
          amount += t.num_price;
        }
      });
      this.fraudAmount = amount.toLocaleString(undefined, { minimumFractionDigits: 2 });
    },
    logout() {
      localStorage.removeItem('userToken'); // Adjust based on your storage method
      localStorage.removeItem('startDate');
      localStorage.removeItem('endDate');
      localStorage.removeItem('exclusions');
      this.$store.dispatch('logout'); // If using Vuex

      this.$router.push("/login");
    },
    checkUrlParameter() {
      let url_str = window.location.toString()
      let url = new URL(url_str.replace("/#/", "/"))
      let params = new URLSearchParams(url);
      params = new URLSearchParams(url.search);
      const card = params.get('card');
      if (card) {
        this.card = card
        this.cardNumber = card
      }
      // const cardNumber = params.get('cardNumber');
      // if (cardNumber) {
      //   this.cardNumber = cardNumber
      // }
      this.removeUrlParameter();
    },
    removeUrlParameter() {
      const url = new URL(window.location);
      url.searchParams.delete('card');
      // url.searchParams.delete('cardNumber');
      window.history.pushState({}, '', url);
    },
  },
  watch: {
    searchQuery(value) {
      let result = this.transactions;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  }
};
</script>

<style>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

#mainarr th {
  background-color: unset;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 96px
}
.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}

</style>

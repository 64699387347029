<template>
  <div class="fixed-plugin" v-click-outside="closeDropDown">
    <!-- <div class="dropdown show-dropdown" :class="{ show: isOpenCSVExport }">
      <el-tooltip content="Exporter en CSV" effect="light" :open-delay="300" placement="left">
        <a data-toggle="dropdown" class="settings-icon">
          <i class="fa fa-cloud-download-alt fa-2x" @click="toggleDropDownCSVExport"> </i>
        </a>
      </el-tooltip>
      <ul class="dropdown-menu" :class="{ show: isOpenCSVExport }">
        <li class="header-title">Exporter en CSV</li>
        <li class="adjustments-line">
          <p class="text-center">Voulez-vous vraiment télécharger les liens affichés ?</p>
        </li>
        <li class="button-container mt-4 text-center">
          <base-button type="primary" round @click="downloadToCSV">Télecharger</base-button>
        </li>
      </ul>
    </div> -->

    <div class="dropdown show-dropdown" :class="{ show: isOpenDoublons }">
      <el-tooltip content="URLs en doublons" effect="light" :open-delay="300" placement="left">
        <a data-toggle="dropdown" class="settings-icon">
          <i class="fa fa-link fa-2x" @click="toggleDropDownDoublons"> </i>
        </a>
      </el-tooltip>
      <ul class="dropdown-menu" :class="{ show: isOpenDoublons, 'not-empty': !doublons || doublons.length > 0}">
        <li class="header-title">URLs en doublons</li>
        <li v-if="doublons && doublons.length === 0" class="adjustments-line">
            Pas d'url en doublons pour le moment.
        </li>
        <li v-else v-for="[url, items] in doublons" :key="url" class="adjustments-line" style="margin-bottom: 20px;">
          <base-button type="primary" simple round @click="filterByURL(url)" class="pull-right">{{ items.length }} occurrences</base-button>
          {{ url }}
        </li>
      </ul>
    </div>

    <div class="dropdown show-dropdown" :class="{ show: isOpenHidden }">
      <el-tooltip content="Éléments masqués" effect="light" :open-delay="300" placement="left">
        <a data-toggle="dropdown" class="settings-icon">
          <i class="fa fa-eye-slash fa-2x" @click="toggleDropDownHidden"></i>
        </a>
      </el-tooltip>
      <ul class="dropdown-menu" :class="{ show: isOpenHidden, 'not-empty': results.filter((item) => item.checked).length > 0 }">
        <li class="header-title">Éléments masqués</li>
        <li v-if="results.filter((item) => item.checked).length === 0" class="adjustments-line">
          Aucun élément masqué.
        </li>
        <li v-else v-for="item in results.filter((item) => item.checked)" :key="item.id" class="adjustments-line">
          <base-button type="primary" simple round icon @click="unhide(item)" class="fa fa-eye fa-2x pull-right"></base-button>
          {{ item.url }}
        </li>
        <li class="button-container mt-4 text-center" v-if="results.filter((item) => item.checked).length > 0">
          <base-button type="primary" round @click="unhideAll">Tout afficher</base-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'sidebar-search-tools',
    props: {
      doublons: [],
      results: [],
    },
    data() {
      return {
        isOpenCSVExport: false,
        isOpenDoublons: false,
        isOpenHidden: false,
      };
    },
    methods: {
      toggleDropDownCSVExport() {
        this.isOpenCSVExport = !this.isOpenCSVExport;
        this.isOpenDoublons = false;
        this.isOpenHidden = false;
      },
      toggleDropDownDoublons() {
        this.isOpenCSVExport = false;
        this.isOpenDoublons = !this.isOpenDoublons;
        this.isOpenHidden = false;
      },
      toggleDropDownHidden() {
        this.isOpenCSVExport = false;
        this.isOpenDoublons = false;
        this.isOpenHidden = !this.isOpenHidden;
      },
      closeDropDown() {
        this.isOpenCSVExport = false;
        this.isOpenDoublons = false;
        this.isOpenHidden = false;
      },
      downloadToCSV() {
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += `"URL";"file";"text";"date"\n`;
        csvContent += this.results.map((hit) => `"${hit.url}";"${hit.file}";"${hit.text}";${hit.date}`).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "results.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.closeDropDown();
        this.$notify({
          type: 'primary',
          message: "Téléchargement en cours...",
          icon: 'tim-icons icon-cloud-download-93'
        });
      },
      filterByURL(url) {
        this.$emit('filter-url', url);
        this.closeDropDown();
      },
      unhide(item) {
        this.$emit('unhide', item);
        this.closeDropDown();
      },
      unhideAll() {
        this.$emit('unhide-all');
        this.closeDropDown();
      },
    }
  };
</script>

<style scoped lang="scss">
  @import '~@/assets/sass/dashboard/custom/variables';

  .settings-icon {
    cursor: pointer;
  }

  .not-empty {
    width: 1200px !important;
    max-height: 600px;
    overflow-y: scroll;
  }
</style>

import { render, staticRenderFns } from "./SidebarSearchToolsPlugin.vue?vue&type=template&id=9868f620&scoped=true"
import script from "./SidebarSearchToolsPlugin.vue?vue&type=script&lang=js"
export * from "./SidebarSearchToolsPlugin.vue?vue&type=script&lang=js"
import style0 from "./SidebarSearchToolsPlugin.vue?vue&type=style&index=0&id=9868f620&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9868f620",
  null
  
)

export default component.exports
<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)" autocomplete="off">
          <card id="form-reset-password" class="mb-0">
            <template slot="header"><h4 class="card-title">{{ $t('resetPassword.title') }}</h4></template>
            <div>
              <ValidationProvider name="password" rules="required|min:8" v-slot="{ passed, failed, errors }">
                <base-input required v-model="password" placeholder="New Password"
                            addon-left-icon="tim-icons icon-lock-circle" :error="errors[0]"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]" type="password"></base-input>
              </ValidationProvider>
              <ValidationProvider name="confirmPassword" rules="required|confirmed:password"
                                  v-slot="{ passed, failed, errors }">
                <base-input required v-model="confirmPassword" placeholder="Confirm Password"
                            addon-left-icon="tim-icons icon-lock-circle" :error="errors[0]"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]" type="password"></base-input>
              </ValidationProvider>
            </div>
            <base-alert type="danger" dismissible v-if="errorMessage != ''"><span><b>Error - </b>{{
                errorMessage
              }}</span></base-alert>
            <base-alert type="success" dismissible v-if="successMessage != ''"><span>{{ successMessage }}</span>
            </base-alert>
            <div slot="footer">
              <base-button native-type="submit" type="primary" class="mb-3" size="lg" block>
                {{ $t('resetPassword.btnResetPassword') }}
              </base-button>
            </div>
          </card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script> import {extend} from "vee-validate";
import {required, min, confirmed} from "vee-validate/dist/rules";

extend("required", required);
extend("min", min);
extend("confirmed", confirmed);
import {BaseAlert} from 'src/components';
import swal from "sweetalert2";

export default {
  components: {BaseAlert},
  data() {
    return {token: "", password: "", confirmPassword: "", errorMessage: "", successMessage: ""};
  },
  mounted() {
    this.handleURLParam();
  },
  methods: {
    handleURLParam() {
      const queryParams = new URLSearchParams(window.location.search);
      this.token = queryParams.get('token');
      console.log("token: " + this.token);
      this.removeUrlParameter();

    },
    removeUrlParameter() {
      const url = new URL(window.location);
      url.searchParams.delete('token');
      window.history.pushState({}, '', url);
    },
    async submit() {
      this.$recaptcha('password_change').then((captcha_token) => {
        this.submitForm(captcha_token);
      }).catch(error => {
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Invalid CAPTCHA',
          customClass: {
            confirmButton: 'btn btn-danger btn-fill',
          },
          buttonsStyling: false,
        });
      });
    }
    ,
    async submitForm(captcha_token) {
      try {
        let accessToken = localStorage.getItem("userToken");
        const response = await fetch(this.$apiEndpoint + `/api/users/password-change`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "password": this.password,
            "captcha_token": captcha_token,
            "token": this.token
          })
        });
        console.log(response);

        const responseBody = await response.json(); // This will work for both success and error responses
        console.log(responseBody);
        if (!response.ok) {
          // If the response is not okay, throw an error with the response body
          throw responseBody;
        }

        swal.fire({
          title: 'Welcome!',
          text: 'Password successfully changed. Please Login.',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
          },
          icon: 'success',
        });
      } catch (error) {
        const errorMessage = this.$t("errors.backend."+error.detail) || 'Unknown error occurred';
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: errorMessage,
          customClass: {
            confirmButton: 'btn btn-danger btn-fill',
          },
          buttonsStyling: false,
        });
      }
    }
  }
}
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.card .alert {
  position: relative !important;
  width: 100%;
}

#form-reset-password {
  background-color: white;
  color: black;
  border-radius: 20px;
}

#form-reset-password h4 {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 2em;
}

#form-reset-password i {
  color: black;
}

#form-reset-password input {
  color: black;
}

.modal-body,
.modal-footer {
  padding: 24px 24px 16px 24px;
}
</style>

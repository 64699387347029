<template>
  <div>
    <base-button id="search-button" data-toggle="modal" data-target="#searchModal" type="primary" simple icon
      @click="isOpenFilters = true">
      <i class="tim-icons icon-settings"></i>
    </base-button>

    <modal :show="isOpenFilters" class="modal-filter" id="searchModal" centered show-close
      headerClasses="justify-content-center" @close="closeFiltersModal">
      <h4 slot="header" class="title title-up" style="color: white">Filtres d'exclusion</h4>
      <div class="extended-forms">
        <card style="display: none;"><!-- display: none; -->
          <div class="row">
            <div class="col-sm-12">
              <p slot="header" class="card-title">Mots à exclure de la recherche</p>
              <tags-input v-model="exclusions" placeholder="Saisir un mot" lowercase></tags-input>
            </div>
            <!-- <div class="d-flex align-center">
                    <base-input v-model="newCustomExclusion" label="Mot à exclure de la recherche" single-line hide-details
                      class="flex-grow-1" @keydown.enter="addCustomExclusion" @input="handleInput"></base-input>
                    <base-button icon class="ml-2 custom-color-btn" @click="addCustomExclusion">
                      <i class="tim-icons icon-simple-add"></i>
                    </base-button>
                  </div>
                  <el-tag v-for="(exclusion, index) in customExclusions" :key="generateChipKey(exclusion, index)" closable
                    icon small class="my-1 mot-exclusion" @click:close="removeCustomExclusion(index)">
                    {{ filterCustomExclusion(exclusion) }}
                  </el-tag> -->
          </div>
        </card>
        <card>
          <div class="row">
            <div class="col-sm-6">
              <p slot="header" class="card-title">A partir de</p>
              <base-input>
                <el-date-picker type="date" placeholder="A partir de" v-model="startDate"
                :picker-options="{ disabledDate: (time) => afterEndDate(time), shortcuts: shortcuts }"
                clearable value-format="yyyy-MM-dd">
                </el-date-picker>
              </base-input>
            </div>
            <div class="col-sm-6">
              <p slot="header" class="card-title">Jusqu'au</p>
              <base-input>
                <el-date-picker type="date" placeholder="Jusqu'au" v-model="endDate" clearable value-format="yyyy-MM-dd"
                :picker-options="{ disabledDate: (time) => beforeStartDate(time), shortcuts: shortcuts }">
                </el-date-picker>
              </base-input>
            </div>
          </div>
        </card>
      </div>
      <template slot="footer">
        <base-button type="primary" simple @click="resetFilters">Réinitialiser</base-button>
        <base-button type="primary" @click="applyFilters">Appliquer</base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { Tag, DatePicker, Select, Option } from 'element-ui';
import { TagsInput } from 'src/components/index';
import Modal from "../../components/Modal.vue";

export default {
  name: 'filter-search-button',
  components: {
    Modal,
    TagsInput,
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tag.name]: Tag,
  },
  props: {
    propExclusions: [],
    propStartDate: String,
    propEndDate: String,
  },
  data() {
    return {
      exclusions: this.propExclusions,
      startDate: this.propStartDate,
      endDate: this.propEndDate,
      isOpenFilters: false,
      isFilterModified: false,
      shortcuts: [
        {
          text: 'Today',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        },
        {
          text: 'Yesterday',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          },
        },
        {
          text: 'A week ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          },
        },
        {
          text: 'A month ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', date)
          },
        },
      ]
    };
  },
  methods: {
    init() {
      console.log(this.propExclusions)
      this.exclusions = this.propExclusions;
      this.startDate = this.propStartDate;
      this.endDate = this.propEndDate;
    },
    afterEndDate(time) {
      return time.getTime() > new Date(this.endDate)
    },
    beforeStartDate(time) {
      let beforeDate = new Date(this.startDate);
      beforeDate.setDate(beforeDate.getDate() - 1)
      return time.getTime() < beforeDate
    },
    applyFilters() {
      this.$emit('apply-filters', this.exclusions, this.startDate, this.endDate);
      this.closeFiltersModal();
    },
    resetFilters() {
      this.exclusions = [];
      this.startDate = "";
      this.endDate = "";
    },
    closeFiltersModal() {
      this.isOpenFilters = false;
    },
  }
};
</script>

<style scoped lang="scss">
@import '~@/assets/sass/dashboard/custom/variables';

.settings-icon {
  cursor: pointer;
}

.not-empty {
  width: 1200px !important;
}
</style>

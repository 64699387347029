<template>
    <div>
      <span class="btn btn-primary btn-file mt-3">
        <span class="fileinput-new"><i class="tim-icons icon-upload"></i>{{ selectText }}</span>
        <input type="hidden" value="" name="" />
        <input
          :accept="mime"
          @change="handleUpload"
          type="file"
          name="..."
          class="valid"
          :multiple="false"
          aria-invalid="false"
        />
      </span>
    </div>
</template>
<script>
export default {
  name: 'file-upload',
  props: {
    mime: {
      type: String,
      default: '*',
      description: 'MIME type of files'
    },
    selectText: {
      type: String,
      default: 'Select file'
    },
  },
  data() {
    return {
      file: null
    };
  },
  methods: {
    handleUpload(event) {
      let file = event.target.files[0];
      this.imagePreview = URL.createObjectURL(file);
      this.$emit('change', file);
    },
  }
};
</script>
<style></style>

<template>
  <div class="container">
    <base-alert type="warning" v-if="this.$store.state.user.role !='admin'">
      {{  $t('buy.adminOnly') }}
    </base-alert>
    <tabs type="primary" :centered="true" class="justify-content-center">
      <tab-pane :label="$t('buy.navCreditCard')">
        <pricing-payment-by-credit-card></pricing-payment-by-credit-card>
      </tab-pane>
      <tab-pane :label="$t('buy.navSubscription')">
        <pricing-payment-by-bank-transfer></pricing-payment-by-bank-transfer>
      </tab-pane>
    </tabs>
  </div>
</template>
<script>
import {BaseAlert, BaseProgress, TabPane, Tabs} from 'src/components';
import PricingPaymentByCreditCard from "@/pages/Pages/PricingPaymentByCreditCard.vue";
import PricingPaymentByBankTransfer from "@/pages/Pages/PricingPaymentByBankTransfer.vue";

export default {
  name: 'pricing-page',
  components: {
    BaseAlert,
    TabPane, Tabs,
    PricingPaymentByCreditCard,
    PricingPaymentByBankTransfer
  },
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>
<style>
#next-pricing-table {
  color: white;
  font-size: 1.5em;
  text-decoration-line: underline;
}
</style>

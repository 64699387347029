<template>
  <card
    type="pricing"
    class="card-success card-white card-cstm"
    :class="{'card-raised': isFeatured}"
    footer-classes="text-center mb-3 mt-3"
  >
    <h1 class="card-title pl-3">{{ title }}</h1>
    <img class="card-img" :src="'img/' + imageSrc" alt="Image"/>
    <ul class="list-group">
      <li v-for="(feature , index) in features[this.$i18n.locale || 'en']" :key="index" class="list-group-item">{{ feature }}</li>
    </ul>
    <div class="card-prices">
      <h3 class="text-on-front">{{ Number(price).toLocaleString(this.$i18n.locale || 'en') }}<span>€</span><span
        style="font-weight: normal; font-size: 0.4em">{{ price_detail[this.$i18n.locale || 'en'] }}</span></h3>
      <ul class="plan">
        <li v-for="(footer , index) in footers[this.$i18n.locale || 'en']" :key="index" class="list-group-item">{{ footer }}</li>
      </ul>
    </div>

    <base-button @click="clickBuy" slot="footer" round type="success" class="btn-just-icon">
      {{ $t('buy.btnBuy') }}
    </base-button>
  </card>
</template>
<script>
import {BaseProgress} from 'src/components';
import swal from 'sweetalert2';

export default {
  props: {
    title: String,
    imageSrc: String,
    features: Object,
    price: Number,
    price_detail: Object,
    footers: Object,
    isFeatured: {
      type: Boolean,
      default: false
    },
    price_id: String,
    isSubscription: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickBuy() {
      if (this.isSubscription) {
        const swalConfirm = swal.mixin({
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          buttonsStyling: false,
        });
        swalConfirm
          .fire({
            title: this.$t('buy.subscriptions.confirmTitle'),
            text: this.$t('buy.subscriptions.confirmText'),
            confirmButtonText: this.$t('buy.btnBuy'),
            cancelButtonText: this.$t('buy.btnCancel'),
            showCancelButton: true,
          })
          .then((result) => {
            if (result.value) {
              const swalInfo = swal.mixin({
                customClass: {
                  confirmButton: "btn btn-success btn-fill",
                },
                buttonsStyling: false,
              });
              swalInfo.fire({
                title: this.$t('buy.subscriptions.purchaseTitle'),
                text: this.$t('buy.subscriptions.purchaseText'),
              });
              this.buy();
            }
          });
      } else {
        this.buy();
      }
    },
    buy() {
      this.token = localStorage.getItem("userToken") || "";
      const fetchOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };
      fetch(this.$apiEndpoint + '/api/orgs/' + this.$store.getters.getCurrentOrganisation.id +  '/create_stripe_checkout_session/' + this.price_id, fetchOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            response.json().then((errorResponse) => {
            swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: this.$t("errors.backend."+errorResponse.detail),
            });
          });
          }
        })
        .then(data => {
          const url = data.url;
          if (url) {
            // window.open(url, '_blank');
            window.location.href = url;
          } else {
            console.error('URL not found in the response');
          }
        })
        .catch(error => {
          console.error('There was a problem with your fetch operation:', error);
        });
    }
  },
  components: {}
};
</script>
<style>
.card-cstm {
  min-width: 250px;
}
</style>

<template>
    <div class="content">
      <div class="row mt-5">
        <div class="col-12">
          <card card-body-classes="table-full-width">
            <h4 slot="header" class="card-title">{{ $t('reports.list') }}</h4>
            <div>
              <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
                <base-input>
                  <el-input
                    type="search"
                    class="mb-3 search-input"
                    clearable
                    prefix-icon="el-icon-search"
                    placeholder="Search records"
                    v-model="searchQuery"
                    aria-controls="datatables"
                  ></el-input>
                </base-input>
              </div>
              <el-table id="mainarr" :data="queriedData">
                <el-table-column
                  sortable
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                ></el-table-column>
                <el-table-column :label="$t('reports.user')" :minWidth="250" sortable>
                  <template slot-scope="props">
                    {{ getCurrentUserMail() }}
                  </template>
                </el-table-column>
                <el-table-column :min-width="135" align="right" :label="$t('reports.actions')">
                  <div slot-scope="props">
                    <el-tooltip content="Download XLSX report" effect="light" :open-delay="300" placement="left">
                      <base-button
                        @click.native="handleDownload(props.$index, props.row)"
                        class="btn-link"
                        type="primary"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-cloud-download-93"></i>
                      </base-button>
                    </el-tooltip>
                    <base-button
                      @click.native="handleDelete(props.$index, props.row)"
                      class="remove btn-link"
                      type="danger"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </div>
                </el-table-column>
              </el-table>
            </div>
            <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              ></base-pagination>
            </div>
          </card>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {Table, TableColumn, Select, Option} from 'element-ui';
  import {BasePagination} from 'src/components';
  import Fuse from 'fuse.js';
  import swal from 'sweetalert2';
  import {refreshContext, formatDate} from "@/utils";
  import VueUploadComponent from 'vue-upload-component'
  import { mapMutations } from 'vuex';
  
  export default {
    components: {
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      FileUpload: VueUploadComponent,
    },
    computed: {
      queriedData() {
        let result = this.reports;
        if (this.searchedData.length > 0) {
          result = this.searchedData;
        }
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.searchedData.length > 0 ? this.searchedData.length : this.reports.length;
      }
    },
    data() {
      return {
        token: localStorage.getItem("userToken") || "",
        files: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        newReport: "",
        searchQuery: '',
        propsToSearch: ['query_terms', /*'user_id', 'organisation_id',*/ 'results', 'created'],
        tableColumns: [
          {
            prop: 'query_terms',
            label: this.$t('reports.qryTerms'),
            minWidth: 250
          },
          {
            prop: 'results',
            label: this.$t('reports.results'),
            minWidth: 120
          },
          {
            prop: 'created',
            label: this.$t('reports.created'),
            minWidth: 120
          }
        ],
        // reports: this.$store.state.reports,
        reports: this.getReports() || [],
        // [
        //   {
        //     query_terms: 'Thales',
        //     last_request: '24/03/2024',
        //     last_status: '17'
        //   },
        //   {
        //     query_terms: 'Thales',
        //     last_request: '25/03/2024',
        //     last_status: '0'
        //   }
        // ]
        searchedData: [],
        fuseSearch: null,
        upload: null,
      };
    },
    mounted() {
      this.$store.subscribe((mutation, state) => {
        switch(mutation.type) {
          case 'switchOrganisation':
            this.reports = this.getReports() || [];
            this.pagination.currentPage = 1;
            this.searchedData = [];
          break;
        } 
      });
      this.fuseSearch = new Fuse(this.reports, {
        keys: ['name', 'email'],
        threshold: 0.3
      });
    },
    methods: {
      ...mapMutations([
        'switchOrganisation', // map `this.increment()` to `this.$store.commit('increment')`
      ]),
      getCurrentUserMail() {
        return this.$store.state.user.email
      },
      getOrganisationName(organisation_id) {
        return this.$store.getters.getOrganisationName(organisation_id)
      },
      getReports() {
        this.token = localStorage.getItem("userToken") || "";
        const fetchOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        };
        const apiUrl = this.$apiEndpoint + `/api/orgs/` + this.$store.getters.getCurrentOrganisation.id + `/reports/`;
        fetch(apiUrl, fetchOptions)
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else if (response.status === 401) {
              this.logout();
              throw new Error("Accès non autorisé. Veuillez-vous connecter.");
            } else {
              throw new Error("Unauthorized resource");
            }
          })
          .then((data) => {
            let returnTable = [];
            // let returnTable = [
            //   {
            //     id: 'Test',
            //     query_terms: 'Thales',
            //     last_request: '24/03/2024',
            //     last_status: '17'
            //   },
            //   {
            //     id: 'Test2',
            //     query_terms: 'Thales',
            //     last_request: '25/03/2024',
            //     last_status: '0'
            //   }
            // ];
            data.forEach(d => {
              returnTable.push({
                "id": d.id,
                "query_terms": d.query_terms,
                // "email": "damien.lescos@sitincloud.com",
                "results": d.results,
                "created": formatDate(d.created),
                "file_path": d.file_path,
                "file": d.file,
              });
            });
            this.reports = returnTable;
          })
          .catch((error) => {
            this.$notify({
              type: 'danger',
              message: `Error - ${error.message}...`,
              icon: 'tim-icons icon-bell-55'
            });
          });
      },
      removeReport(index, row) {
        const fetchOptions = {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        };
        const apiUrl = this.$apiEndpoint + `/api/reports/` + row.id;
        fetch(apiUrl, fetchOptions)
          .then((response) => {
            if (response.ok) {
              this.reports.splice(index, 1);
              swal.fire({
                title: 'Deleted!',
                text: `You deleted ${row.query_terms}`,
                icon: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false
              });
              return response.json();
            } else if (response.status === 401) {
              this.logout();
              throw new Error("Accès non autorisé. Veuillez-vous connecter.");
            } else {
              throw new Error("Unauthorized resource");
            }
          })
          .catch((error) => {
            console.error("Error removing report:", error);
          });
      },
      downloadFile(row) {
        this.token = localStorage.getItem("userToken") || "";
        const fetchOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.token}`,
            // "Content-Type": "application/pdf",
          },
        };
        const apiUrl = this.$apiEndpoint + `/api/reports/` + row.id + `/xlsx`;
        fetch(apiUrl, fetchOptions)
          .then((response) => {
            if (response.ok) {
              return response.blob();
            } else if (response.status === 401) {
              this.logout();
              throw new Error("Accès non autorisé. Veuillez-vous connecter.");
            } else {
              throw new Error("Unauthorized resource");
            }
          })
          .then((data) => {
            var file = window.URL.createObjectURL(data);
            window.location.assign(file);
          })
          .catch((error) => {
            this.$notify({
              type: 'danger',
              message: `Error - ${error.message}...`,
              icon: 'tim-icons icon-bell-55'
            });
          });
      },
      handleDownload(index, row) {
        this.downloadFile(row);
        this.$notify({
          type: 'primary',
          message: "Téléchargement en cours...",
          icon: 'tim-icons icon-cloud-download-93'
        });
      },
      handleDelete(index, row) {
        swal.fire({
          title: 'Are you sure?',
          text: `You won't be able to revert this!`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: 'Yes, delete it!',
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            // this.deleteRow(row);
            this.removeReport(index, row);
          }
        });
      },
      deleteRow(row) {
        let indexToDelete = this.reports.findIndex(tableRow => tableRow.id === row.id);
        if (indexToDelete >= 0) {
          this.reports.splice(indexToDelete, 1);
        }
      },
      logout() {
        localStorage.removeItem('userToken'); // Adjust based on your storage method
        localStorage.removeItem('startDate');
        localStorage.removeItem('endDate');
        localStorage.removeItem('exclusions');
        this.$store.dispatch('logout'); // If using Vuex

        this.$router.push("/login");
      }
    },
    watch: {
      searchQuery(value) {
        let result = this.reports;
        if (value !== '') {
          result = this.fuseSearch.search(this.searchQuery);
        }
        this.searchedData = result;
      }
    }
  };
  </script>
  
  <style>
  .pagination-select,
  .search-input {
    width: 200px;
  }
  
  .swal2-icon-content {
    font-size: inherit !important;
  }
  
  #mainarr th {
    background-color: unset;
  }
  </style>
  